import React from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

const ImageText = loadable(() => import("./modules/imageText"));
const IntroBlock = loadable(() => import("./modules/introBlock"));
const FourColumnBlock = loadable(() => import("./modules/fourColumnBlock"));
const TwoColumnText = loadable(() => import("./modules/twoColumnText"));
const Quote = loadable(() => import("./modules/quote"));
const ImageCarousel = loadable(() => import("./modules/imageCarousel"));
const ImageBlock = loadable(() => import("./modules/imageBlock"));
const ProjectGrid = loadable(() => import("./modules/projectGrid"));
const TextBlock = loadable(() => import("./modules/textBlock"));
const TwoImageBlock = loadable(() => import("./modules/twoImageBlock"));
const NewsPortal = loadable(() => import("./modules/newsPortal"));
const ProjectsPortal = loadable(() => import("./modules/projectsPortal"));

const ModuleZone = ({ modules }) => {
  const component = modules.map((item) => {
    switch (item._type) {
      case "imageText":
        return <ImageText {...item} />;
      case "introBlock":
        return <IntroBlock {...item} />;
      case "fourColumnBlock":
        return <FourColumnBlock {...item} />;
      case "twoColumnText":
        return <TwoColumnText {...item} />;
      case "quote":
        return <Quote {...item} />;
      case "imageCarousel":
        return <ImageCarousel {...item} />;
      case "imageBlock":
        return <ImageBlock {...item} />;
      case "projectGrid":
        return <ProjectGrid {...item} />;
      case "textBlock":
        return <TextBlock {...item} />;
      case "twoImageBlock":
        return <TwoImageBlock {...item} />;
      case "newsPortal":
        return <NewsPortal {...item} />;
      case "projectsPortal":
        return <ProjectsPortal {...item} />;
      default:
        return null;
    }
  });
  return <>{component}</>;
};

ModuleZone.propTypes = {
  modules: PropTypes.array.isRequired,
};

export default ModuleZone;
